


























import { Vue, Component } from 'vue-property-decorator'
@Component
export default class search extends Vue {
  formInline = {
    name: '',
    region: '',
    status: ''
  };

  onSubmit (): void {
    console.log('submit!')
  }
}
